import React, { useEffect } from "react";
import Navbar from "./global-components/navbar";
import PageHeader from "./global-components/page-header";
import FaqSection from "./section-components/faq";
import Footer from "./global-components/footer";
import { useForm, ValidationError } from "@formspree/react";

const Contact = () => {
  const [state, handleSubmit] = useForm("xwkavezl");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Navbar />
      <PageHeader headertitle="Contact" />
      <div className="contact-area pd-top-100 pd-bottom-65">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="contact-page-map">
                <iframe
                  className="w-100"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d100814.96848800128!2d144.89506981658934!3d-37.84934411423654!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad646b5d2ba4df7%3A0x4045675218ccd90!2sMelbourne%20VIC%2C%20Australia!5e0!3m2!1sen!2sbd!4v1616864808631!5m2!1sen!2sbd"
                  style={{ border: 0 }}
                  allowFullScreen
                />
              </div>
            </div>
            <div className="col-lg-4">
              <form
                onSubmit={handleSubmit}
                className="contact-form-wrap contact-form-bg"
              >
                <h4>Contact Us:</h4>
                <div className="rld-single-input">
                  <input
                    type="text"
                    placeholder="Full Name"
                    id="name"
                    type="name"
                    name="name"
                  />
                </div>
                <div className="rld-single-input">
                  <input
                    id="email"
                    type="email"
                    name="email"
                    placeholder="Full Name"
                  />
                  <ValidationError
                    className="rld-single-input"
                    style={{ margin: "10px" }}
                    prefix="Email"
                    field="email"
                    errors={state.errors}
                  />
                </div>
                {/* <div className="rld-single-input">
		            <input type="text" placeholder="Phone" />
		          </div> */}
                <div className="rld-single-input">
                  <textarea
                    name="message"
                    rows={12}
                    placeholder="Message"
                    defaultValue={""}
                  />
                  <ValidationError
                    prefix="Message"
                    field="message"
                    errors={state.errors}
                    className="rld-single-input"
                    style={{ margin: "10px" }}
                  />
                </div>

                {state.succeeded && (
                  <p
                    style={{
                      margin: "10px",
                      color: "orange",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    Thanks for contacting us! We will get back to you soon.
                  </p>
                )}
                <div className="btn-wrap text-center">
                  <button
                    className="btn btn-yellow"
                    type="submit"
                    disabled={state.submitting}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="row pd-top-92">
            {/* <div className="col-xl-3 col-sm-6">
		        <div className="single-contact-info">
		          <p><i className="fa fa-phone" />Call Us:</p>
		          <h5>(000) 111 222 333</h5>
		        </div>
		      </div> */}
            {/* <div className="col-xl-3 col-sm-6">
		        <div className="single-contact-info">
		          <p><i className="fa fa-fax" />Fax:</p>
		          <h5>(000) 111 222 333</h5>
		        </div>
		      </div> */}
            <div className="col-xl-3 col-sm-2">
              <div className="single-contact-info">
                <p>
                  <i className="fa fa-envelope" />
                  Have any Question?
                </p>
                <h6>info@thegrantsinstitute.com</h6>
              </div>
            </div>
            <div className="col-xl-4 col-sm-6">
              <div className="single-contact-info">
                <p>
                  <i className="fa fa-map-pin" />
                  Located in
                </p>
                <h6>Melbourne, Australia</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
