import contextFactory from "./contextFactory";

const initialState = {
  searchText: '',
  location: '',
  industry: '',
  category: '',
};

const searchReducer = (state, action) => {
  switch (action.type) {
    case "SET_SEARCH_TEXT":
      return { ...state, searchText: action.payload };
    case "SET_LOCATION":
      return { ...state, location: action.payload };
    case "SET_INDUSTRY":
      return { ...state, industry: action.payload };
    case "SET_CATEGORY":
      return { ...state, category: action.payload };
    default:
      return state;
  }
};

const searchActions = {
  setSearchText: (dispatch) => (searchText) => {
    dispatch({ type: "SET_SEARCH_TEXT", payload: searchText });
  },
  setLocation: (dispatch) => (location) => {
    dispatch({ type: "SET_LOCATION", payload: location });
  },
  setIndustry: (dispatch) => (industry) => {
    dispatch({ type: "SET_INDUSTRY", payload: industry });
  },
  setCategory: (dispatch) => (category) => {
    dispatch({ type: "SET_CATEGORY", payload: category });
  },
};

export const { Context, Provider } = contextFactory(
  searchReducer,
  { ...searchActions },
  initialState
);
