import React, { useState, useEffect, useContext } from "react";
// import sectiondata from "../../data/sections.json";
import grantsList from "../../data/grantData.json";
import { Link } from "react-router-dom";
// import Form from "./../Form/Form";
// import { useDispatch, useSelector } from "react-redux";
import Fuse from "fuse.js";
import InfiniteScroll from "react-infinite-scroller";
// import { getFilteredPosts } from "../../api";
// import { getFilteredPosts } from "../../actions/posts";
import { Context as SearchContext } from "../../../src/Contexts/SearchContext";

require("es6-promise").polyfill();
require("isomorphic-fetch");

const SearchList = ({ setCurrentId }) => {
  let grantListsLocal = grantsList;
  // let publicUrl = process.env.PUBLIC_URL + "/";
  // let imagealt = "image";
  // let data = sectiondata.searchlist;
  //   const dispatch = useDispatch();
  const {
    state: { searchText, location, industry, category },
    setSearchText,
    setLocation,
    setIndustry,
    setCategory,
  } = useContext(SearchContext);

  // const [searchText, setSearchText] = useState("");
  // const [searchTextImported, setSearchTextImported] = useState(null);
  // const history = useHistory();
  //pagitation
  const [filteredGrantList, setfilteredGrantList] = useState([]);

  const [allposts, setAllPosts] = useState(grantListsLocal);
  const [posts, setPosts] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [curpage, setCurpage] = useState(0);
  const [pagesize, setPagesize] = useState(20);
  const [total, setTotal] = useState(grantListsLocal.length);
  const [totalPage, setTotalPage] = useState();

  const [searchedTotals, setSearchedTotals] = useState(
    filteredGrantList.length
  );
  // boolean on off for filters
  const [searchOn, setSearchOn] = useState(false);
  // const [categoryOn, setCategoryOn] = useState(false);
  // const [locationOn, setlocationOn] = useState(false);
  // const [industryOn, setIndustryOn] = useState(false);
  // const posts = useSelector((state) => state.posts);

  const SearchOptions = {
    shouldSort: true,
    threshold: 0.4,
    location: 0,
    distance: 100,
    matchAllTokens: true,
    includeMatches: true,
    minMatchCharLength: 0,
    keys: [
      "_id",
      "Idnum",
      "Title",
      "Openingdates",
      "FullBodyText",
      "Category",
      "Status",
      "Location",
      "ProgramAdmin",
      "GeneralWebsite",
      "ProgramWebsite",
    ],
  };

  const MAX_LENGTH = 550;
  const fuse = new Fuse(grantListsLocal, SearchOptions);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (filteredGrantList.length > 0) {
      setAllPosts(filteredGrantList);
      console.log("filtered grant list block");

      // const pathArray = window.location.pathname.split('/');
      // if(pathArray[1] !== ''){
      //   setSearchOn(true);
      //   handleSearchText(searchText);
      // }
      // const postList = allposts.slice(
      //   curpage * pagesize,
      //   (curpage + 1) * pagesize
      // );
      // console.log("first useEffect");
      // setPosts(postList);
      // setTotalPage(Math.ceil(total / pagesize));
    }
  }, []);

  useEffect(() => {
    const pathArray = window.location.href.split("/");
    console.log("patharray", pathArray);
    if (pathArray.length > 5) {
      setSearchOn(true);
      setSearchText(pathArray[5]);
      // handleSearchText(pathArray[5]);
    }
  }, []);

  useEffect(() => {
    const result = fuse.search(searchText);
    const postList = grantListsLocal.slice(
      curpage * pagesize,
      (curpage + 1) * pagesize
    );
    // setfilteredGrantList(result);
    // setAllPosts(result);
    if (result.length > 0) {
      setSearchOn(true);
      handleSearchText(searchText);
    }
    console.log("Result", result);
    setTotal(result.length);
    setTotalPage(Math.ceil(total / pagesize));
    // const postList = result.slice(curpage * pagesize, (curpage + 1) * pagesize);
    setPosts(postList);
    //   console.log(postList);
    console.log("second useEffect");
  }, [searchText]);

  const loadmoreItem = async (event) => {
    // console.log("load more");
    console.log(searchText);
    if (curpage + 1 < totalPage) {
      console.log("if current page greater page");
      setCurpage(curpage + 1);
      setPosts(allposts.slice(0, (curpage + 1) * pagesize));
      console.log(allposts);
      // setCurpage(curpage);
    } else {
      setHasMore(false);
    }
  };

  function handleSearchText(searchTexthere) {
    const result = fuse.search(searchText);
    setfilteredGrantList(result);
    console.log(result);
    console.log("search text", searchText);
    //   setAllPosts(fuse.search(searchTexthere));
    // }
  }

  const handleSearchChange = (event) => {
    event.preventDefault();
    const searchText = event.target.value;
    setSearchText(searchText);
    // console.log(searchText);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" || event.key === "NumpadEnter") {
      event.preventDefault();
      setSearchOn(true);
      handleSearchText(searchText);
    }
  };

  function handleCategory(category) {
    // setCategoryOn(true);
    setCategory(category);
    setfilteredGrantList(fuse.search(category));
    setSearchOn(true);
    console.log("category", category);
  }

  function handlelocationFilter(location) {
    setSearchOn(true);
    // setlocationOn(true);
    setLocation(location);
    console.log("location", location);
    // if (industryOn) {
    //   setIndustryOn(false);
    const result = fuse.search(location);
    setfilteredGrantList(result);
    // } else {
    //   const result = fuse.search(location);
    //   setfilteredGrantList(result);
    // }
  }

  function handleIndustryFilter(industry) {
    setSearchOn(true);
    // setIndustryOn(true);
    setIndustry(industry);
    console.log("industry", industry);
    const result = fuse.search(industry);
    setfilteredGrantList(result);
    // console.log(result);
  }

  return (
    <InfiniteScroll
      pageStart={0}
      loadMore={loadmoreItem}
      hasMore={hasMore}
      useWindow={false}
      threshold={10000}
    >
      <div
        className="search-page-wrap"
        hasMore={hasMore}
        posts={posts}
        loadmoreItem={(event) => {
          loadmoreItem(event);
        }}
      >
        <div className="search-page-wrap pd-top-60 pd-bottom-80">
          <div className="search-container">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-xl-3 col-lg-4 sitebar">
                  <div
                    style={{
                      textAlign: "center",
                      paddingBottom: "40px",
                      margin: "0",
                    }}
                  >
                    {!searchOn && (
                      <h6>{allposts.length} Search results found</h6>
                    )}

                    {searchOn && (
                      <h6>{filteredGrantList.length} Search results found</h6>
                    )}

                    {/* <h6>{posts.length} Search results found</h6> */}
                    {/* {searchTextImported && <h6> for ({searchTextImported})</h6>} */}
                  </div>
                  <form
                    className="widget widget-sidebar-search-wrap"
                    // onSubmit={(event) => handleSearchText(searchText)}
                  >
                    <div className="widget-sidebar-search">
                      <div className="widget-sidebar-item-wrap btn-area">
                        <button
                          className="btn btn-yellow"
                          onClick={() => handleCategory("business")}
                        >
                          Business
                        </button>
                        <a
                          className="btn btn-yellow float-right"
                          onClick={() => handleCategory("community")}
                        >
                          Community
                        </a>
                      </div>
                      <div
                        className="widget-sidebar-item-wrap rld-single-input"
                        style={{ marginBottom: "5px" }}
                      >
                        <input
                          onChange={(event) => {
                            handleSearchChange(event);
                          }}
                          onKeyUp={(event) => handleKeyPress(event)}
                          type="text"
                          placeholder="Search Keywords"
                        />
                      </div>
                      <div className="text-center" style={{ margin: "0px" }}>
                        - or -
                      </div>
                      <div
                        className="widget-sidebar-item-wrap rld-single-select"
                        style={{ marginBottom: "5px" }}
                      >
                        <select
                          className="select single-select"
                          onChange={(event) =>
                            handleIndustryFilter(event.target.value)
                          }
                        >
                          <option value={""}>All Categories</option>
                          <option value={"Arts"}>Arts</option>
                          <option value={"Community Services"}>
                            Community Services
                          </option>
                          <option value={"Education"}>Education</option>
                          <option value={"Environment"}>Environment</option>
                          <option value={"Health"}>Health</option>
                          <option value={"Infrastructure"}>
                            Infrastructure
                          </option>
                          <option value={"Manufacturing"}>Manufacturing</option>
                          <option value={"Marketing"}>Marketing</option>
                          <option value={"Training/Employment"}>
                            Training/Employment
                          </option>
                          <option value={"Transport and Distribution"}>
                            Transport and Distribution
                          </option>
                          <option value={"Multicultural"}>Multicultural</option>
                          <option value={"Sports"}>Sports</option>
                          <option value={"Innovation"}>Innovation</option>
                          <option value={"Wholesale and Retail Trade"}>
                            Wholesale and Retail Trade
                          </option>
                        </select>
                      </div>
                      <div className="text-center" style={{ margin: "0px" }}>
                        - or -
                      </div>
                      <div
                        className="widget-sidebar-item-wrap rld-single-select"
                        style={{ marginBottom: "5px" }}
                      >
                        <select
                          className="select single-select"
                          onChange={(event) => {
                            handlelocationFilter(event.target.value);
                          }}
                        >
                          <option value={""}>All Locations</option>
                          <option value={"Australian Capital Territory"}>
                            Australian Capital Territory
                          </option>
                          <option value={"National"}>National</option>
                          <option value={"Tasmania"}>Tasmania</option>
                          <option value={"New South Wales"}>
                            New South Wales
                          </option>
                          <option value={"Northern Territory"}>
                            Northern Territory
                          </option>
                          <option value={"Queensland"}>Queensland</option>
                          <option value={"South Australia"}>
                            South Australia
                          </option>
                          <option value={"Western Australia"}>
                            Western Australia
                          </option>
                          <option value={"Victoria"}>Victoria</option>
                          <option value={"Overseas"}>Overseas</option>
                        </select>
                      </div>
                    </div>
                    <div className="btn-wrap text-center">
                      <button
                        onClick={(event) => handleSearchText(searchText)}
                        type="submit"
                        className="btn btn-yellow"
                      >
                        <span className="left">
                          <i className="fa fa-search" />
                        </span>
                        Search Keyword
                      </button>
                    </div>
                  </form>
                </div>
                <div className="col-xl-9 col-lg-8">
                  {!searchOn &&
                    posts.map((post) => (
                      <div className="single-feature style-two">
                        <div className="details">
                          <div className="details-wrap">
                            <p className="author">Status: {post.Status}</p>
                            <h6 className="title readeal-top">
                              <Link
                                to={`/grant-details/${post.Idnum}`}
                                id={post.Idnum}
                              >
                                {post.Title}
                              </Link>
                            </h6>

                            <h7 className="price">
                              <h6 className="author">
                                Category: {post.Category[0]} and{" "}
                                {post.Category[1]}
                              </h6>
                              {post.Overview.length > MAX_LENGTH ? (
                                <div>
                                  {`${post.Overview.substring(
                                    0,
                                    MAX_LENGTH
                                  )}...`}
                                  <Link
                                    style={{ color: "#fda94f" }}
                                    to={`/grant-details/${post.Idnum}`}
                                    id={post.Idnum}
                                  >
                                    Read more
                                  </Link>
                                </div>
                              ) : post.Overview.length < 1 ? (
                                <div>
                                  {`${post.FullBodyText.substring(
                                    0,
                                    MAX_LENGTH
                                  )}...`}
                                  <Link
                                    style={{ color: "#fda94f" }}
                                    to={`/grant-details/${post.Idnum}`}
                                    id={post.Idnum}
                                  >
                                    Read more
                                  </Link>
                                </div>
                              ) : (
                                <p>{post.Overview}</p>
                              )}
                            </h7>
                            <h6 className="price">{post.industry}</h6>
                            <ul className="contact-list">
                              <li>
                                <i
                                  className="fa fa-map-pin"
                                  style={{ marginRight: "20px" }}
                                />
                                <a className="location" href="#">
                                  Location: {post.Location}{" "}
                                </a>
                              </li>
                              <li className="readeal-top">
                                <Link
                                  to={`/grant-details/${post.Idnum}`}
                                  id={post.Idnum}
                                  className="btn btn-yellow"
                                  style={{ marginLeft: "30px" }}
                                  // onClick={(event) => handleProductPageId(event)}
                                >
                                  View Details
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    ))}

                  {searchOn &&
                    filteredGrantList.map((post) => (
                      <div className="single-feature style-two">
                        <div className="details">
                          <div className="details-wrap">
                            <p className="author">
                              Status: {post.item?.Status}
                            </p>
                            <h6 className="title readeal-top">
                              <Link
                                to={`/grant-details/${post.item?.Idnum}`}
                                id={post.item?.Idnum}
                              >
                                {post.item?.Title}
                              </Link>
                            </h6>

                            <h7 className="price">
                              <h6 className="author">
                                Category: {post.item?.Category[0]} and{" "}
                                {post.item?.Category[1]}
                              </h6>
                              {post.item?.Overview.length > MAX_LENGTH ? (
                                <div>
                                  {`${post.item?.Overview.substring(
                                    0,
                                    MAX_LENGTH
                                  )}...`}
                                  <Link
                                    style={{ color: "#fda94f" }}
                                    to={`/grant-details/${post.item?.Idnum}`}
                                    id={post.item?.Idnum}
                                  >
                                    Read more
                                  </Link>
                                </div>
                              ) : post.item?.Overview.length < 1 ? (
                                <div>
                                  {`${post.item?.FullBodyText.substring(
                                    0,
                                    MAX_LENGTH
                                  )}...`}
                                  <Link
                                    style={{ color: "#fda94f" }}
                                    to={`/grant-details/${post.item?.Idnum}`}
                                    id={post.item?.Idnum}
                                  >
                                    Read more
                                  </Link>
                                </div>
                              ) : (
                                <p>{post.item?.Overview}</p>
                              )}
                            </h7>
                            <h6 className="price">{post.item?.industry}</h6>
                            <ul className="contact-list">
                              <li>
                                <a className="location" href="#">
                                  Location: {post.item?.Location}{" "}
                                  <i className="fa fa-map-pin" />
                                </a>
                              </li>
                              <li className="readeal-top">
                                <Link
                                  to={`/grant-details/${post.item?.Idnum}`}
                                  id={post.item?.Idnum}
                                  className="btn btn-yellow"
                                  style={{ marginLeft: "30px" }}
                                >
                                  View Details
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    ))}

                  {!searchOn && (
                    <button
                      className={"btn"}
                      onClick={(event) => loadmoreItem(event)}
                      style={{
                        margin: "30px",
                        width: "250px",
                        position: "absolute",
                        left: "30%",
                      }}
                    >
                      Load More
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </InfiniteScroll>
  );
};

export default SearchList;

{
  /* {searchOn &&
                finalPosts.map((finalPost, index) => (
                  <div className="single-feature style-two">
                    <div className="details">
                      <div className="details-wrap">
                        <p className="author">
                          {finalPost.item.category}{" "}
                          {finalPost.item?._id || finalPost._id}
                        </p>
                        <h6 className="title readeal-top">
                          <Link
                            to={`/grant-details/${finalPost._id}`}
                            id={finalPost._id}
                            onClick={(event) =>
                              handleProductPageId(grantsList[index])
                            }
                          >
                            {finalPost.item?.title}
                          </Link>
                        </h6>
                        <h7 className="price">{finalPost.item?.description}</h7>
                        <h6 className="price">{finalPost.item?.activities}</h6>
                        <h6 className="price">{finalPost.item?.industry}</h6>
                        <ul className="contact-list">
                          <li>
                            <a className="location" href="#">
                              Location: {finalPost.item?.location}{" "}
                              <i className="fa fa-map-pin" />
                            </a>
                          </li>
                          <li className="readeal-top">
                            <button
                              type="submit"
                              // key={index}
                              key={index}
                              className="btn btn-yellow"
                              // onClick={(event) => handleProductPageId(event)}
                              // onClick={() => console.log(grantsList[index])}
                              onClick={(event) =>
                                handleProductPageId(grantsList[index])
                              }
                            >
                              View Details
                            </button>
                          </li>
                          <h6 className="price">
                            {" "}
                            Max ${finalPost.item?.grantAmount}
                          </h6>
                        </ul>
                      </div>
                    </div>
                  </div>
                ))} */
}
{
  /* <Form setCurrentId={setCurrentId} /> */
}
