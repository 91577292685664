import React, { Component, useEffect } from "react";
import { Link } from "react-router-dom";
import footerdata from "../../data/footerdata.json";
import { useForm, ValidationError } from "@formspree/react";

const Footer_v1 = () => {
  useEffect(() => {
    let publicUrl = process.env.PUBLIC_URL + "/";
    const minscript = document.createElement("script");
    minscript.async = true;
    minscript.src = publicUrl + "assets/js/main.js";

    document.body.appendChild(minscript);
  }, []);

  const [state, handleSubmit] = useForm("xwkavezl");

  let publicUrl = process.env.PUBLIC_URL + "/";
  let imgattr = "Footer logo";
  const inlineStyle = {
    backgroundImage: "url(" + publicUrl + footerdata.footerbg + ")",
  };

  return (
    <footer className="footer-area style-two">
      <div className="container">
        <div className="subscribe-area">
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-9 text-center">
              <h2>Never miss out on funding. </h2>
              <p>Stay updated with our funding alerts.</p>
              {state.succeeded ? (
                <div style={{ color: "grey" }}>Thanks for subscribing!</div>
              ) : (
                <form className="rld-single-input" onSubmit={handleSubmit}>
                  <input
                    required={"true"}
                    id="email1"
                    type="email"
                    name="email"
                    placeholder="Your email Address"
                  />
                  <ValidationError
                    className="rld-single-input"
                    style={{ margin: "10px" }}
                    prefix="Email"
                    field="email"
                    errors={state.errors}
                  />

                  <button
                    className="btn"
                    type="submit"
                    disabled={state.submitting}
                  >
                    Sign up
                  </button>
                </form>
              )}
            </div>
          </div>
        </div>
        {/* <div className="footer-top">
                  <div className="row">
                    <div className="col-sm-4">
                      <a className="footer-logo" href="#"><img src={ publicUrl+footerdata.footerlogo } alt={ imgattr } /></a>
                    </div>
                    <div className="col-sm-8">
                      <div className="footer-social text-sm-right">
                        <span>FOLLOW US</span>
                        <ul className="social-icon">
                         { footerdata.socialicon.map( ( item, i )=>
                            <li key={i}>
                              <a href={ item.url } target="_blank"><i className={ item.icon } /></a>
                            </li>
                          ) }
                        </ul>
                      </div>  
                    </div>
                  </div>
                </div> */}
        <div className="footer-bottom">
          <div className="row">
            <div className="col-lg-3 col-sm-6">
              <div className="widget widget_nav_menu">
                <h4 className="widget-title">
                  {footerdata.popularserces.title}
                </h4>
                <ul>
                  {footerdata.popularserces.links.map((item, i) => (
                    <li key={i}>
                      <Link to={`/search-list/${item.url}`}>{item.title}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="widget widget_nav_menu">
                <h4 className="widget-title">{footerdata.Homepress.title}</h4>
                <ul>
                  {footerdata.Homepress.links.map((item, i) => (
                    <li key={i}>
                      <Link to={`/search-list/${item.url}`}>{item.title}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="widget widget_nav_menu">
                <h4 className="widget-title">{footerdata.quicklink.title}</h4>
                <ul>
                  {footerdata.quicklink.links.map((item, i) => (
                    <li key={i}>
                      <Link to={item.url}>{item.title}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            {state.succeeded ? (
              <p>Thanks for subscribing!</p>
            ) : (
              <div className="col-lg-3 col-sm-6">
                <form
                  className="widget widget-subscribe"
                  onSubmit={handleSubmit}
                >
                  <div className="rld-single-input">
                    {/* <label htmlFor="name">Full Name</label> */}
                    <input
                      required={"true"}
                      type="text"
                      placeholder="Full Name"
                      id="name"
                      type="name"
                      name="name"
                    />
                  </div>
                  <div className="rld-single-input">
                    {/* <label htmlFor="email">Email Address</label> */}
                    <input
                      required={"true"}
                      id="email2"
                      type="email"
                      name="email"
                      placeholder="your@email.com"
                    />
                    <ValidationError
                      className="rld-single-input"
                      style={{ margin: "10px" }}
                      prefix="Email"
                      field="email"
                      errors={state.errors}
                    />
                  </div>
                  <button
                    type="submit"
                    disabled={state.submitting}
                    className="btn btn-yellow w-100"
                  >
                    Subscribe
                  </button>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer_v1;
