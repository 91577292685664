import React, { Component } from "react";
import sectiondata from "../../data/sections.json";
import parse from "html-react-parser";
import { Link } from "react-router-dom";

class Ads2 extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imagealt = "image";
    let data = sectiondata.ads2;

    const inlineStyle = {
      backgroundImage:
        "url(" + publicUrl + "assets/img/banner/back8orange.png)",
      opacity: 0.9
    };

    return (
      <div className="call-to-action-area pd-top-100 pd-bottom-90">
        <div className="container-for-grantCTA">
          {/* <div className="call-to-action" style={ inlineStyle }> Bring back for purple */}
          <div className="call-to-action" style={inlineStyle}>
            <div className="cta-content">
              <h3 className="title">{data.title}</h3>
              {/* <div className={"ads-span-div"}> */}
              <h6 className="we-do-grants-differently">{data.btntxt}</h6>
              <Link className="btn btn-white" to={"/pricing"}>
                Find Out How We Can Help
              </Link>
            </div>
            {/* </div> */}
          </div>
        </div>
      </div>
    );
  }
}

export default Ads2;
