import React from 'react';

//import all contexts
import { Provider as SearchProvider } from './SearchContext';

const CombineProviders = ({ children }) => {
  return (
 <SearchProvider>{children}</SearchProvider>
  );
};

export default CombineProviders;
