import React, { Component, useState, useEffect } from "react";
import sectiondata from "../../data/sections.json";
import parse from "html-react-parser";
import { useForm, ValidationError } from "@formspree/react";

const AddNew = () => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  let imagealt = "image";
  let data = sectiondata.aboutus;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [state, handleSubmit] = useForm("xknkorab");

  return (
    <div className="add-new-property-area pd-top-90 mg-bottom-100">
      <div className="container">
        <form className="contact" onSubmit={handleSubmit}>
          <div className="row justify-content-center">
            {state.succeeded ? (
              <div>
                <h6 style={{ textAlign: "center" }}>
                  Thank you for applying to The Grants Institute!
                </h6>
                <h4 style={{ textAlign: "center", color: "#fda94f" }}>
                  We look forward to helping you get closer to your funding
                  goals.
                </h4>
                <h6 style={{ textAlign: "center" }}>
                  One of our grant specialists will be in touch with you
                  shortly.
                </h6>
              </div>
            ) : (
              <div className="col-xl-9 col-lg-10">
                <div className="section-title text-center">
                  <h3>Tell Us More</h3>
                </div>
                <div className="border-bottom mb-4">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="single-intro style-two text-center">
                        <div className="thumb">1</div>
                        <div className="details">
                          <h4 className="title">Add Your Information</h4>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="single-intro style-two text-center">
                        <div className="thumb">2</div>
                        <div className="details">
                          <h4 className="title">We Review Your Needs</h4>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="single-intro style-two text-center">
                        <div className="thumb">3</div>
                        <div className="details">
                          <h4 className="title">We'll Write Your Grant</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row pd-top-100">
                  <div className="col-md-4">
                    <div className="section-title">
                      <h4>Application Details*</h4>
                    </div>
                  </div>
                  <div className="col-md-8">
                    {/* <div className="section-title"> */}
                    <div className="rld-single-input">
                      <label for="businessName" style={{ paddingLeft: "10px" }}>
                        Business/Organisation
                      </label>
                      <input
                        required={"true"}
                        type="text"
                        placeholder="Your Organisation Name"
                        id="businessName"
                        type="name"
                        name="name"
                      />
                    </div>
                    <div
                      className="rld-single-input"
                      style={{ paddingTop: "10px" }}
                    >
                      <label
                        for="applicantName"
                        style={{ paddingLeft: "10px" }}
                      >
                        Your Full Name
                      </label>
                      <input
                        required={"true"}
                        type="text"
                        placeholder="Applicant Name"
                        id="applicantName"
                        type="name"
                        name="name"
                      />
                    </div>
                    <div
                      className="rld-single-input"
                      style={{ paddingTop: "10px" }}
                    >
                      <label for="email" style={{ paddingLeft: "10px" }}>
                        Email Address{" "}
                      </label>
                      <input
                        required={"true"}
                        id="email"
                        type="email"
                        name="email"
                        placeholder="your@email.com"
                      />
                      <ValidationError
                        className="rld-single-input"
                        style={{ margin: "10px" }}
                        prefix="Email"
                        field="email"
                        errors={state.errors}
                      />
                    </div>
                    <div
                      className="rld-single-input"
                      style={{ paddingTop: "15px" }}
                    >
                      <textarea
                        required={"true"}
                        id="message"
                        name="message"
                        placeholder="Describe Your Grant Writing Requirements"
                        type="text"
                        multiline={12}
                        style={{ borderRadius: "5px", background: "#f1f1f1" }}
                        rows={7}
                      />
                      <ValidationError
                        prefix="Message"
                        field="message"
                        errors={state.errors}
                        className="rld-single-input"
                        style={{ margin: "10px" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="row pd-top-60">
                  <div className="col-md-4">
                    <div className="section-title">
                      <h4>
                        <img
                          src={publicUrl + "assets/img/icons/29.png"}
                          alt="img"
                        />
                        Location*
                      </h4>
                    </div>
                  </div>
                  <div className="col-md-8 rld-single-input">
                    <input
                      required={"true"}
                      id="location"
                      name="location"
                      placeholder="Location"
                      type="text"
                    />
                    <ValidationError
                      prefix="Location"
                      field="location"
                      errors={state.errors}
                      className="rld-single-input"
                    />
                  </div>
                </div>
                <div className="row pd-top-60">
                  <div className="col-md-4">
                    <div className="section-title">
                      <h4>Funding Amount Required</h4>
                    </div>
                  </div>

                  <div className="col-md-8">
                    <fieldset id="fs-frm-selects">
                      <div className="row">
                        <div className="col-12 mb-3">
                          <div className="rld-single-select">
                            <select className="select single-select">
                              <option value={1}>Choose Amount</option>
                              <option value={2}>Less than 10,000</option>
                              <option value={3}>$10,000-50,000</option>
                              <option value={3}>50,000+</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-12 mb-3">
                          <div className="rld-single-select">
                            <select
                              className="select single-select"
                              name="Stage Of Organization"
                              id="stageOfOrganization"
                            >
                              <option
                                value={"Stage Of Organization"}
                                selected=""
                                disabled=""
                              >
                                Stage of organization
                              </option>
                              <option value={"Under 1 year"}>
                                Under 1 year
                              </option>
                              <option value={"2-3 years"}>2-3 years</option>
                              <option value={"3-5 years"}>3-5 years</option>
                              <option value={"5+ years"}>5+ years</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-12 mb-3">
                          <div className="rld-single-select">
                            <select
                              className="select single-select"
                              name="Team Size"
                              id="TeamSize"
                            >
                              <option value={1} selected="" disabled="">
                                Team Size
                              </option>
                              <option value={"1-2"}>1-2</option>
                              <option value={"2-10"}>2-10</option>
                              <option value={"10-50"}>10-50</option>
                              <option value={"50+"}>50+</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div>
                <div className="row pd-top-80">
                  <button
                    className="btn btn-yellow"
                    style={{ margin: "auto", width: "280px" }}
                    type="submit"
                    disabled={state.submitting}
                  >
                    Send Grant Inquiry
                  </button>
                </div>
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddNew;
