import React from "react";
import { useSelector } from "react-redux";
import Post from "../Posts/Post/Post";
import { Grid, CircularProgress  } from '@material-ui/core';


const Posts = ({ setCurrentId }) => {

  const posts = useSelector((state) => state.posts);
  console.log(posts);
  return ( 
    !posts.length ? <CircularProgress /> : (
      <Grid conainter alignItems="stretch">
      {posts.map((post) => (
        <Grid key={post._id} item xs={23}>
        <Post post={post} setCurrentId={setCurrentId}/>
      </Grid>
      ))}
      </Grid>
  ))
};

export default Posts;
