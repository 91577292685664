import React, { useEffect } from "react";
import styled from "styled-components";
import Navbar from "./global-components/navbar";
import PageHeader from "./global-components/page-header";
import Footer from "./global-components/footer";

const Consultation = (url) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://assets.calendly.com/assets/external/widget.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <Navbar />
      <PageHeader headertitle="Book Your Consultation" subheader="Calendar" />
      <h5 className="title" style={{ textAlign: "center", paddingTop: "40px" }}>
        Schedule a meeting with one of our specialists
      </h5>
      <CustomWindow>
        <div
          className="calendly-inline-widget"
          data-url={"https://calendly.com/thegrantsinstitute"}
          style={{ width: "100%", height: "100vh" }}
        >
          <script
            src={"https://assets.calendly.com/assets/external/widget.js"}
          ></script>
        </div>
      </CustomWindow>
      <Footer />
    </>
  );
};

export default Consultation;

const CustomWindow = styled.div`
  height: 100%;
  width: 100%;
`;
