import React, { Component } from "react";
// import {useEffect} from 'react-dom';
import sectiondata from "../../../data/sections.json";
import parse from "html-react-parser";
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Button,
  Typography,
} from "@material-ui/core";
import Posts from "../Posts";

const Post = ({ post, setCurrentId }) => {
  // console.log(post, post._id)

  let publicUrl = process.env.PUBLIC_URL + "/";
  let imagealt = "image";

  return (
    <div className="property-details-area">
      {/* <div className="bg-gray pd-top-100 pd-bottom-90"> */}
      <div className="container">
        <div className="row ">
          <div className="col-xl-9 col-lg-8">
            <div className="property-details-slider">
              <div className="item">
                <div className="thumb">
                  {/* <img
                        src={publicUrl + "assets/img/news/19.png"}
                        alt={imagealt}
                      /> */}
                </div>
              </div>
              {/* <div className="item">
                    <div className="thumb">
                      <img
                        src={publicUrl + "assets/img/news/19.png"}
                        alt={imagealt}
                      />
                    </div>
                  </div> */}
            </div>
            <div className="container">
              <div className="property-details-slider-info">
                <h3>Title</h3>
              </div>
            </div>
            <Posts setCurrentId={"605f8a443060544934cd0500"} />

            {/* <div className="thumb">
                      <img
                        src={publicUrl + "assets/img/news/21.png"}
                        alt={imagealt}
                      />
                    </div> */}
            {/* <div className="details">
                      <h6>Jesse Edwards</h6>
                      <span className="designation">Building Owner</span>
                      <p className="reviews">
                        <i className="fa fa-star" />
                        <span>4.8</span> 70 Review
                      </p>
                    </div> */}
          </div>
        </div>
        {/* </div> */}

        <div className="row pd-top-90">
          <div className="col-lg-9">
            <div className="property-info mb-5">
              <div className="row">
                <div className="col-md-3 col-sm-6">
                  <div className="single-property-info">
                    <h5>Grant Status</h5>
                    <p>
                      <i className="fa fa-circle" />
                      Open
                    </p>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6">
                  <div className="single-property-info">
                    <h5>Max Contribution</h5>
                    <p>
                      <i className="fa fa-dollar" />
                      20,000
                    </p>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6">
                  <div className="single-property-info">
                    <h5>Region</h5>
                    <p>
                      <img
                        src={publicUrl + "assets/img/icons/1.png"}
                        alt={imagealt}
                      />
                      National
                    </p>
                  </div>
                </div>
                {/* <div className="col-md-3 col-sm-6">
                    <div className="single-property-info">
                      <h5>Parking</h5>
                      <p>
                        <i className="fa fa-car" />
                        01 Indoor
                      </p>
                    </div>
                  </div> */}
              </div>
            </div>
            <div className="property-news-single-card style-two border-bottom-yellow">
              <h4>Grant Overview</h4>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. In
                bibendum elit magna, ut placerat nunc tempus vel. Donec vitae
                dictum ligula. Phasellus congue maximus eleifend. Vestibulum
                ante ipsum primis in faucibus orci luctus et ultrices posuere
                cubilia Curae; Suspendisse potenti. Suspendisse sollicitudin
                posuere nunc et vehicula. Interdum et malesuada fames ac ante
                ipsum primis in faucibus. Maecenas aliquam vitae quam at
                sodales.{" "}
              </p>
              <a href="#">Read More</a>
            </div>
            <div className="property-news-single-card border-bottom-yellow">
              <h4>Grant Eligibility Criteria</h4>
              <div className="thumb">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. In
                  bibendum elit magna, ut placerat nunc tempus vel. Donec vitae
                  dictum ligula. Phasellus congue maximus eleifend. Vestibulum
                  ante ipsum primis in faucibus orci luctus et ultrices posuere
                  cubilia Curae; Suspendisse potenti. Suspendisse sollicitudin
                  posuere nunc et vehicula. Interdum et malesuada fames ac ante
                  ipsum primis in faucibus. Maecenas aliquam vitae quam at
                  sodales.{" "}
                </p>
              </div>
            </div>
            <div className="property-news-single-card border-bottom-yellow pb-3">
              <h4>Grant Administrator Information</h4>
              <div className="row">
                <div className="col-md-3 col-sm-6">
                  <div className="single-floor-list media">
                    <div className="media-left">
                      <i className="fa fa-phone" />
                    </div>
                    <div className="media-body">
                      <h6>Phone Number</h6>
                      <p>67848991222</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6">
                  <div className="single-floor-list media">
                    <div className="media-left">
                      <i className="fa fa-envelope" />
                    </div>
                    <div className="media-body">
                      <h6>Email Address</h6>
                      <p>hello@govt.com</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6">
                  <div className="single-floor-list media">
                    <div className="media-left">
                      <img
                        src={publicUrl + "assets/img/icons/environment.png"}
                        alt={imagealt}
                      />
                    </div>
                    <div className="media-body">
                      <h6>Grant Website</h6>
                      <p>www.grantsite.com</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6">
                  <div className="single-floor-list media">
                    <div className="media-left">
                      <img
                        src={publicUrl + "assets/img/icons/4.png"}
                        alt={imagealt}
                      />
                    </div>
                    <div className="media-body">
                      <h6>Administrator</h6>
                      <p>AUHS Ausralian Gvoernemnt</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="property-news-single-card border-bottom-yellow pb-3 ">
              <div className="contact">
                <h6 style={{ margin: "20px" }}>Contact Us</h6>
                <div className="rld-single-input" style={{ margin: "10px" }}>
                  <input type="text" placeholder="Full Name" />
                </div>
                <div className="rld-single-input" style={{ margin: "10px" }}>
                  <input type="text" placeholder="Email" />
                </div>
                <div className="rld-single-input" style={{ margin: "10px" }}>
                  {" "}
                  <input type="text" placeholder="Message" />
                </div>
                <button className="btn btn-yellow" style={{ margin: "10px" }}>
                  Contact Us
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Post;

//This code is to add a contact us form to each product
//-->
// <div className="col-xl-3 col-lg-4">
// <div className="widget widget-owner-info mt-lg-0 mt-5">
//   <div className="owner-info text-center">
//   </div>
//   <div className="contact">
//     <h6>Contact Us</h6>
//     <div className="rld-single-input">
//       <input type="text" placeholder="Full Name" />
//     </div>
//     <div className="rld-single-input">
//       <input type="text" placeholder="Email" />
//     </div>
//     <div className="rld-single-input">
//       <input type="text" placeholder="Messages" />
//     </div>
//     <a className="btn btn-yellow" href="#">
//       Contact Us
//     </a>
//   </div>
//   {/* <div className="contact-info">
//     <h6 className="mb-3">Contact Info</h6>
//     <div className="media">
//       <div className="media-left">
//         <img
//           src={publicUrl + "assets/img/icons/1.png"}
//           alt={imagealt}
//         />
//       </div>
//       <div className="media-body">
//         <p>Address</p>
//         <span>Long Island, NY 11355, USA</span>
//       </div>
//     </div>
//     <div className="media">
//       <div className="media-left">
//         <i className="fa fa-phone" />
//       </div>
//       <div className="media-body">
//         <p>Phone</p>
//         <span>+00 111 222 333</span>
//       </div>
//     </div>
//     <div className="media mb-0">
//       <div className="media-left">
//         <i className="fa fa-envelope" />
//       </div>
//       <div className="media-body">
//         <p>Email</p>
//         <span>info@example.com</span>
//       </div>
//     </div>
//   </div> */}
// </div>
// </div>
{
  /* <>
<Card >
    <h5>{ post.title }</h5>
    <Button onClick={()=> setCurrentId(post._id)} > Edit </Button>

    <CardMedia title={post.title}></CardMedia>

<div> { post._id }</div>
<div> { post.title }</div>
<div> { post.description }</div>
<div> { post.category }</div>
<div> { post.grantAmount }</div>
<div> { post.activities }</div>
<div> { post.location }</div>
<div> { post.section }</div>

</Card>


</>
);
}

export default Post; */
}
