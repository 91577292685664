import React, { Component } from "react";
import sectiondata from "../../data/sections.json";
import parse from "html-react-parser";
import { Link } from "react-router-dom";
import Carousel from "slick-carousel";

class Service extends Component {
  //REAL HOMEPAGE SCROLLER OF IDUSTRY

  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imagealt = "image";
    let data = sectiondata.services2;

    return (
      <div
        className="service-area"
        style={{
          display: "flex",
          textAlign: "center",
          paddingRight: "50px",
          paddingLeft: "50px",
          paddingTop: "40px",
          maxWidth: "100%",
        }}
      >
        <div className="">
          <div
            style={{
              display: "flex",
              textAlign: "center",
              alignContent: "center",
              overflow: "auto",
              maxWidth: "100%",
            }}
          >
            {data.items.map((item, i) => (
              <div
                key={i}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  textAlign: "center",
                  padding: "0px 10px 0 10px",
                  minWidth: "200px",
                }}
              >
                <Link className="current-menu-item" to={item.url}>
                  <div className="single-service text-center">
                    <div>
                      <img src={publicUrl + item.icon} alt="icons" />
                    </div>
                    <div className="details readeal-top">
                      <h4>{item.title}</h4>
                      {/* <Link className="readmore-btn" to="/grant-details">{ item.btntxt } <i className="la la-arrow-right" /></Link> */}
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default Service;
