import React, { useState, useEffect } from "react";
// import posts from '../../reducers/posts'
import { TextField, Button, Typography, Paper } from "@material-ui/core";
import FileBase from "react-file-base64";
import { useDispatch, useSelector } from "react-redux";
import { createPost, updatePost } from "../../actions/posts";

const Form = (currentId, setCurrentId) => {
  const [postData, setPostData] = useState({
    category: "",
    title: "",
    description: "",
    grantAmount: "",
    activities: "",
    location: "",
    industry: "",
  });
  const post = useSelector((state) =>
    currentId ? state.posts.find((p) => p._id === currentId) : null
  );
  const dispatch = useDispatch();

  const clear = () => {};

  useEffect(() => {
    if (post) setPostData(post);
  }, [post]);

  const getValue = (e) => {
    const { value } = e.currentTarget;
    return value;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // if(currentId){
    //   dispatch(updatePost(currentId, postData))
    //   console.log(currentId, postData);
    // } else {
    try {
      dispatch(createPost(postData));
      console.log(postData);
    } catch (error) {
      console.log(error);
    }

    // }
  };

  return (
    <Paper>
      <form autoComplete="off" nonValidate onSubmit={handleSubmit}>
        <Typography variant="h6"> Upload a grant </Typography>
        <TextField
          name="category"
          variant="outlined"
          label="Category"
          fullWidth
          value={postData.category}
          onChange={(e) => setPostData({ ...postData, category: getValue(e) })}
        />
        <TextField
          name="title"
          variant="outlined"
          label="Title"
          fullWidth
          value={postData.title}
          onChange={(e) => setPostData({ ...postData, title: getValue(e) })}
        />
        <TextField
          name="description"
          variant="outlined"
          label="Description"
          fullWidth
          value={postData.description}
          onChange={(e) =>
            setPostData({ ...postData, description: getValue(e) })
          }
        />
        <TextField
          name="grantAmount"
          variant="outlined"
          label="Grant Amount"
          fullWidth
          value={postData.grantAmount}
          onChange={(e) =>
            setPostData({ ...postData, grantAmount: getValue(e) })
          }
        />
        <TextField
          name="activities"
          variant="outlined"
          label="Activities"
          fullWidth
          value={postData.activities}
          onChange={(e) =>
            setPostData({ ...postData, activities: getValue(e) })
          }
        />
        <TextField
          name="location"
          variant="outlined"
          label="Location"
          fullWidth
          value={postData.location}
          onChange={(e) => setPostData({ ...postData, location: getValue(e) })}
        />
        <TextField
          name="industry"
          variant="outlined"
          label="Industry"
          fullWidth
          value={postData.industry}
          onChange={(e) => setPostData({ ...postData, industry: getValue(e) })}
        />
        <div>
          <Button
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            fullwidth
          >
            Submit
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={clear}
            fullwidth
          >
            Clear
          </Button>
        </div>
      </form>
    </Paper>
  );
};

export default Form;
