import React, { Component } from "react";
import sectiondata from "../../data/sections.json";
import parse from "html-react-parser";
import {Link} from 'react-router-dom'

class OurPartner extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imagealt = "image";
    let data = sectiondata.partner;

    return (
      <div className="client-area pd-top-90 pd-bottom-80">
        <div className="container">
          <div className="section-title text-center">
            <h2 className="title">Successful Grants</h2>
          </div>
          {/* <div className="client-slider"> */}
          <div
              style={{
                display: "flex",
                textAlign: "center",
                width: "100%",
              }}
            >
            {data.items.map((item, i) => (
              <div key={i}  style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
                textAlign: "center",
                padding: "40px 20px 0 20px",
                width: "100%",
              }}>
                <div className="thumb">
                  <img src={publicUrl + item.image} alt="client" />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default OurPartner;
