import React, { Component, useState, useEffect} from "react";
import Navbar from "./global-components/navbar";
import Banner from "./section-components/banner";
import Service from "./section-components/service";
import Ads from "./section-components/ads";
import Ads2 from "./section-components/ads2";
import WhyChooseUs from "./section-components/why-choose-us";
import OurPartner from "./section-components/our-partner";
import Footer from "./global-components/footer-v2";
import Client from "./section-components/client";


const Home_V1 = () => {
  return (
    // <Provider store={store}>
    <div>
      <Navbar />
      <Banner />
      <Service />
      {/* <Posts setCurrentId={setCurrentId}/>
      <Form currentId={currentId} setCurrentId={setCurrentId}/> */}

      {/* <ServiceTwo/> */}
      <Ads2 />
      {/* <Explore /> */}
      {/* <FeaturedProperties /> */}

      {/* <PropertiesByCities />
        <RecentProperties /> */}
      {/* <FeaturedPorject /> */}
      <WhyChooseUs />
      <Ads />
      <OurPartner />
      <Client />
      <Footer />
    </div>
    // </Provider>
  );
};

export default Home_V1;
