import React, { Component, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import sectiondata from "../../data/sections.json";
import grantsList from "../../data/grantData.json";
import { useSelector } from "react-redux";
import { useForm, ValidationError } from "@formspree/react";

const PropertyDetails = () => {
  let grantListsLocal = grantsList;

  const posts = useSelector((state) => state.posts);
  const [currentPostData, setCurrentPostData] = useState({});
  const [StatusOpen, setStatusOpen] = useState("green");
  const [state, handleSubmit] = useForm("xwkavezl");

  useEffect(() => {
    const windowUrl = window.location.href;
    const currentURL = windowUrl.split("grant-details/");
    const thisId = currentURL[1];

    const currentPost = grantListsLocal.find((e) => e.Idnum == thisId);
    setCurrentPostData(currentPost);

    if (currentPostData.Status === "Open") {
      setStatusOpen("green");
    } else {
      setStatusOpen("red");
    }
  }, [currentPostData]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let publicUrl = process.env.PUBLIC_URL + "/";
  let imagealt = "image";
  // console.log("secondone:" + currentPostData);

  return (
    <div className="property-details-area">
      {currentPostData && (
        <div className="container">
          <div className="row ">
            <div className="col-xl-9 col-lg-8">
              <div className="property-details-slider">
                <div className="item">
                  <div className="thumb"></div>
                </div>
              </div>

              <div className="property-details-slider-info">
                <h3>{currentPostData.Title}</h3>
              </div>
            </div>
          </div>

          <div className="row pd-top-90">
            <div className="col-lg-8">
              <div className="property-news-single-card style-two border-bottom-yellow">
                <h4>Grant Overview</h4>
                <p>{currentPostData.Overview}</p>
              </div>
              <div className="property-news-single-card border-bottom-yellow">
                <h4>Grant Details</h4>
                <div className="thumb">
                  <p>{currentPostData.FullBodyText}</p>
                </div>
              </div>
              <div className="property-news-single-card style-two border-bottom-yellow">
                <h4>Eligible Activities</h4>
                <p>{currentPostData.EligibleActivities}</p>
              </div>
              <div className="property-news-single-card border-bottom-yellow">
                <h4>Eligible Applicants</h4>
                <div className="thumb">
                  <p>{currentPostData.EligibleApplicants}</p>
                </div>
              </div>
              <div className="property-news-single-card border-bottom-yellow pb-3">
                <h4>Grant Administrator Information</h4>
                <div className="row">
                  <div className="col-md-4 col-sm-6">
                    <div className="single-floor-list media">
                      <div className="media-left">
                        <img
                          src={publicUrl + "assets/img/icons/education.png"}
                          alt={imagealt}
                        />
                      </div>
                      <div className="media-body">
                        <h6>Administrator</h6>
                        <p>{currentPostData.ProgramAdmin}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <div className="single-floor-list media">
                      <div className="media-left">
                        <img
                          src={publicUrl + "assets/img/icons/environment.png"}
                          alt={imagealt}
                        />
                      </div>
                      <div className="media-body">
                        <a
                          target="_blank"
                          href={currentPostData.GeneralWebsite}
                        >
                          General Website
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <div className="single-floor-list media">
                      <div className="media-left">
                        <img
                          src={publicUrl + "assets/img/icons/environment.png"}
                          alt={imagealt}
                        />
                      </div>
                      <div className="media-body">
                        <a
                          target="_blank"
                          href={currentPostData.ProgramWebsite}
                        >
                          Grant Website
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-md-3 col-sm-6">
                    <div className="single-floor-list media">
                      <div className="media-left">
                        <img
                          src={publicUrl + "assets/img/icons/4.png"}
                          alt={imagealt}
                        />
                      </div>
                      <div className="media-body">
                        <h6>Administrator</h6>
                        <p>{currentPostData.ProgramAdmin}</p>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
              <div className="property-news-single-card border-bottom-yellow pb-3 ">
                {/* <form className="contact" onSubmit={handleSubmit}>
                  <h6 style={{ margin: "20px" }}>Contact Us</h6>
                  <label htmlFor="email">Email Address</label>
                  <input id="email" type="email" name="email" />
                  <ValidationError
                    className="rld-single-input"
                    style={{ margin: "10px" }}
                    prefix="Email"
                    field="email"
                    errors={state.errors}
                  />
                  <textarea id="message" name="message" />
                  <ValidationError
                    prefix="Message"
                    field="message"
                    errors={state.errors}
                    className="rld-single-input"
                    style={{ margin: "10px" }}
                  />
                  <button
                    type="submit"
                    disabled={state.submitting}
                    className="btn btn-yellow"
                    style={{ margin: "10px" }}
                  >
                    Submit
                  </button>
                </form>
                {state.succeeded && <p>Thanks for contacting us!</p>} */}

                {state.succeeded ? (
                  <p>Thanks for contacting us! We will get back to you soon.</p>
                ) : (
                  <div className="contact">
                    <h6 style={{ margin: "20px" }}>Contact Us</h6>
                    <form className="contact" onSubmit={handleSubmit}>
                      <div
                        className="rld-single-input"
                        style={{ margin: "10px" }}
                      >
                        {/* <label htmlFor="name">Full Name</label> */}
                        <input
                          required={"true"}
                          type="text"
                          placeholder="Full Name"
                          id="name"
                          type="name"
                          name="name"
                        />
                      </div>
                      <div
                        className="rld-single-input"
                        style={{ margin: "10px" }}
                      >
                        {/* <label htmlFor="email">Email Address</label> */}
                        <input
                          required={"true"}
                          id="email"
                          type="email"
                          name="email"
                          placeholder="Email address"
                        />
                        <ValidationError
                          className="rld-single-input"
                          style={{ margin: "10px" }}
                          prefix="Email"
                          field="email"
                          errors={state.errors}
                        />
                      </div>
                      <div
                        className="rld-single-input"
                        style={{ margin: "10px" }}
                      >
                        <textarea
                          required={"true"}
                          id="message"
                          name="message"
                        />
                        <ValidationError
                          prefix="Message"
                          field="message"
                          errors={state.errors}
                          className="rld-single-input"
                          style={{ margin: "10px" }}
                        />
                      </div>
                      <button
                        className="btn btn-yellow"
                        style={{ margin: "10px" }}
                        type="submit"
                        disabled={state.submitting}
                      >
                        Contact Us
                      </button>
                    </form>
                  </div>
                )}
              </div>
            </div>

            <div className="col-xl-4 col-lg-5">
              <div className="widget widget-owner-info mt-lg-0 mt-5">
                <div className="contact">
                  <h6>Grant Status</h6>
                  <span className="designation">
                    <i className="fa fa-circle" style={{ color: StatusOpen }} />{" "}
                    {currentPostData.Status}
                  </span>
                </div>
                <div className="contact">
                  <h6>Location</h6>
                  {currentPostData.Location}
                </div>
                <div className="contact">
                  <h6 className="mb-3">Grant Amount</h6>
                </div>
                <div className="contact">
                  <h6 className="mb-3">Dates</h6>
                  {currentPostData.OpeningDates}
                </div>
                <div className="contact">
                  <h6 className="mb-3">Administrator</h6>
                  {currentPostData.ProgramAdmin}
                </div>
                <div className="contact">
                  <h6 className="mb-3">General Website</h6>
                  <a style={{ color: "#fda94f", fontWeight: "bold", textDecoration:"underline" }} href={currentPostData.ProgramWebsite}  target={"_blank"} href={currentPostData.GeneralWebsite}>General Website Link</a>
                </div>
                <div className="contact">
                  <h6 className="mb-3">Grant Website</h6>
                 <a style={{ color: "#fda94f", fontWeight: "bold", textDecoration:"underline" }} href={currentPostData.ProgramWebsite} target={"_blank"}>Program Website Link</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PropertyDetails;

//This code is to add a contact us form to each product
//-->
// <div className="col-xl-3 col-lg-4">
// <div className="widget widget-owner-info mt-lg-0 mt-5">
//   <div className="owner-info text-center">
//   </div>
//   <div className="contact">
//     <h6>Contact Us</h6>
//     <div className="rld-single-input">
//       <input type="text" placeholder="Full Name" />
//     </div>
//     <div className="rld-single-input">
//       <input type="text" placeholder="Email" />
//     </div>
//     <div className="rld-single-input">
//       <input type="text" placeholder="Messages" />
//     </div>
//     <a className="btn btn-yellow" href="#">
//       Contact Us
//     </a>
//   </div>
//   {/* <div className="contact-info">
//     <h6 className="mb-3">Contact Info</h6>
//     <div className="media">
//       <div className="media-left">
//         <img
//           src={publicUrl + "assets/img/icons/1.png"}
//           alt={imagealt}
//         />
//       </div>
//       <div className="media-body">
//         <p>Address</p>
//         <span>Long Island, NY 11355, USA</span>
//       </div>
//     </div>
//     <div className="media">
//       <div className="media-left">
//         <i className="fa fa-phone" />
//       </div>
//       <div className="media-body">
//         <p>Phone</p>
//         <span>+00 111 222 333</span>
//       </div>
//     </div>
//     <div className="media mb-0">
//       <div className="media-left">
//         <i className="fa fa-envelope" />
//       </div>
//       <div className="media-body">
//         <p>Email</p>
//         <span>info@example.com</span>
//       </div>
//     </div>
//   </div> */}
// </div>
// </div>

{
  /* <div className="row">
<div className="col-md-3 col-sm-6">
  <div className="single-property-info">
    <h5>Grant Status:</h5>
    <p>
      <i className="fa fa-circle" />
      {currentPostData.Status}
    </p>
  </div>
</div> */
}
{
  /* <div className="col-md-3 col-sm-6">
  <div className="single-property-info">
    <h5>Max Contribution</h5>
    <p>
      <i className="fa fa-dollar" />
      {currentPostData.grantAmount}
    </p>
  </div>
</div> */
}
{
  /* <div className="col-md-3 col-sm-6">
  <div className="single-property-info">
    <h5>Location: </h5>
    <p>
      <img
        src={publicUrl + "assets/img/icons/1.png"}
        alt={imagealt}
      />
      {currentPostData.Location}
    </p>
  </div>
</div>
<div className="col-md-3 col-sm-6">
  <div className="single-property-info">
    <h5>Category </h5>
    <p>
      <img
        src={publicUrl + "assets/img/icons/1.png"}
        alt={imagealt}
      />
      {currentPostData.Category}
    </p>
  </div>
</div>
</div> */
}
