import React, { useState, useEffect, useContext } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import sectiondata from "../../data/sections.json";
import ServiceGrants from "./serviceGrants";
import { Context as SearchContext } from "../../../src/Contexts/SearchContext";

const Banner = () => {
  // const [searchTextSent, setSearchTextSent] = useState("");
  const [sending, setSending] = useState(false);
  const history = useHistory();
  // const location = useLocation();
  const [categoryOn, setCategoryOn] = useState(false);
  // const [category, setCategory] = useState("");

  const {
    state: { searchText, location, industry, category },
    setSearchText,
    setCategory,
  } = useContext(SearchContext);

  useEffect(() => {
    const $ = window.$;
    if ($(".single-select").length) {
      $(".single-select").niceSelect();
    }
  }, []);

  const handleKeyPress = (event) => {
    if (event.key === "Enter" || event.key === "NumpadEnter") {
      // console.log("Enter key was pressed. Run your function.");
      // event.preventDefault();
      handleSelectChange(event);
      console.log(searchText);
      setSending(true);
    }
  };

  useEffect(() => {
    if (sending) {
      // if (category) {
      //   history.push(`/search-list/${category}`);
      // } else {
      setSearchText(searchText);
      history.push(`/search-list/${searchText}`);
      // }
    }
    return () => {};
  }, [sending]);

  function handleSelectChange(event) {
    // event.preventDefault();
    const selectElement = event.target.value;
    setSearchText(selectElement);
    // setSearchTextSent(selectElement.value);
    console.log(searchText);
  }

  function handleCategory(event) {
    setCategoryOn(true);
    setCategory(category);
  }

  const publicUrl = process.env.PUBLIC_URL + "/";
  const imagealt = "image";
  const data = sectiondata.banner;
  const item = sectiondata.bannersales;

  const inlineStyle = {
    backgroundImage:
      "url(" + publicUrl + "assets/img/banner/background_gradient.jpg)",
  };

  const styles = {
    bold: { fontWeight: "bold" },
    italic: { fontStyle: "italic" },
    underline: { textDecorationLine: "underline" },
  };

  // const handleSearchText = (event) => {
  //   // event.preventDefault();
  //   const searchTextSent = event.target.value;
  //   setSearchTextSent(searchTextSent);
  //   // console.log(searchTextSent);
  // };

  return (
    <div className="banner-area jarallax" style={inlineStyle}>
      <div className="container">
        <div className="banner-inner-wrap">
          <div className="row">
            <div className="col-12">
              <div className="banner-inner">
                <h1
                  className="title"
                  style={{ textAlign: "center", paddingTop: "20px" }}
                >
                  {data.title1}{" "}
                </h1>
                <h5 className="sub-title">{data.subtitle}</h5>
                <ServiceGrants />
              </div>
            </div>
            {/* <div> 27 Expert grant writers</div> */}
            {/* ftco-counter */}
            {/* <BannerImagesSales/> */}
            <div className="col-12">
              <div className="banner-search-wrap">
                <ul className="nav nav-tabs rld-banner-tab">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      data-toggle="tab"
                      href="#tabs_1"
                      onClick={()=>{setCategory("business")}}
                    >
                      Business
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" data-toggle="tab" href="#tabs_2" onClick={()=>{setCategory("community")}}>
                      Community
                    </a>
                  </li>
                </ul>
                <div className="tab-content">
                  <div className="tab-pane fade show active" id="tabs_1">
                    <div className="rld-main-search">
                      {/* <div className="col-xl-4 col-lg-6 col-md-6"></div> */}
                      <div className="row">
                        <div className="col-xl-9 col-lg-12 col-md-12">
                          <div className="rld-single-input">
                            <input
                              onChange={(event) => handleSelectChange(event)}
                              onKeyUp={(event) => handleKeyPress(event)}
                              type="text"
                              placeholder="Search Keywords"
                            />
                          </div>
                        </div>
                        {/* <div
                          className="responsive-mobile-menu"
                          style={{ marginTop: "10px", textAlign: "center" }}
                        >
                          - or -
                        </div>
                        <div className="col-xl-3 col-lg-6 col-md-6">
                        <div className="rld-single-select">
                            <select
                              className="select single-select"
                              onChange={handleCategory}
                              onSelect={(event) =>
                                handleSelectChange(event.target.value)
                              }
                            >
                              <option value={"All Categories"}>
                                All Categories
                              </option>
                              <option value={"Arts"}>Arts</option>
                              <option value={"Community"}>
                                Community Services
                              </option>
                              <option value={"Education"}>Education</option>
                              <option value={"Environment"}>Environment</option>
                              <option value={"Health"}>Health</option>
                              <option value={"Infrastructure"}>
                                Infrastructure
                              </option>
                              <option value={"Manufacturing"}>
                                Manufacturing
                              </option>
                              <option value={"Marketing"}>Marketing</option>
                              <option value={"Employment"}>
                                Training/Employment
                              </option>
                              <option value={"Transport"}>
                                Transport and Distribution
                              </option>
                              <option value={"Multicultural"}>
                                Multicultural
                              </option>
                              <option value={"Sports"}>Sports</option>
                              <option value={"Innovation"}>Innovation</option>
                              <option value={"Wholesale and Retail Trade"}>
                                Wholesale and Retail Trade
                              </option>
                            </select>
                          </div> */}
                        {/* </div> */}
                        <div className="col-xl-3 col-lg-6 col-md-6 readeal-top">
                          {/* <Link
                            className="btn btn-yellow"
                            to={{
                              pathname: "/search-list",
                              state: {search: searchTextSent},
                            }}
                          > */}
                          <Link className="btn btn-yellow" to={`/search-list/business`}>
                            Search Business Grants
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="tabs_2">
                    <div className="rld-main-search">
                      <div className="row">
                        <div className="col-xl-9 col-lg-12 col-md-12">
                          <div className="rld-single-input">
                            <input
                              type="text"
                              placeholder="Search Keywords"
                              onSelect={(event) => handleSelectChange(event)}
                              onKeyUp={(event) => handleKeyPress(event)}
                              onChange={handleCategory}
                            />
                          </div>
                        </div>
                        {/* <div className="responsive-mobile-menu" style={{ marginTop: "10px", textAlign: "center"}}>
                          - or -
                        </div>
                        <div className="col-xl-3 col-lg-6 col-md-6">
                          <div className="rld-single-select">
                            <select
                              className="select single-select"
                              onChange={(event) =>  handleSelectChange(event.target.value)}
                            >
                              <option value={"All Categories"}>
                                All Categories
                              </option>
                              <option value={"Arts"}>Arts</option>
                              <option value={"Community Services"}>
                                Community Services
                              </option>
                              <option value={"Education"}>Education</option>
                              <option value={"Environment"}>Environment</option>
                              <option value={"Health"}>Health</option>
                              <option value={"Infrastructure"}>
                                Infrastructure
                              </option>
                              <option value={"Manufacturing"}>
                                Manufacturing
                              </option>
                              <option value={"Marketing"}>Marketing</option>
                              <option value={"Training/Employment"}>
                                Training/Employment
                              </option>
                              <option value={"Transport and Distribution"}>
                                Transport and Distribution
                              </option>
                              <option value={"Multicultural"}>
                                Multicultural
                              </option>
                              <option value={"Sports"}>Sports</option>
                              <option value={"Innovation"}>Innovation</option>
                              <option value={"Wholesale and Retail Trade"}>
                                Wholesale and Retail Trade
                              </option>
                            </select>
                          </div>
                        </div> */}
                        <div className="col-xl-3 col-lg-6 col-md-6 readeal-top">
                          <Link className="btn btn-yellow" to={`/search-list/community`}>
                            {/* <Link
                            className="btn btn-yellow"
                            to={{
                              pathname: "/search-list/",
                            aboutProps: {
                              sentText: searchTextSent
                            }}
                          }
                          > */}
                            Search Community Grants
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
