import React, { Component } from "react";
import sectiondata from "../../data/sections.json";
import parse from "html-react-parser";
import { Link } from "react-router-dom";

class serviceGrants extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imagealt = "image";
    let data = sectiondata.services;

    return (

        <div className="">
          <div
            style={{
              display: "flex",
              textAlign: "center",
              width: "auto",
              position: "relative",
            }}
          >
            {data.items.map((item, i) => (
              <div
                key={i}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  textAlign: "center",
                  padding: "-60px 0px -50px 0px",
                  width: "50%",
                }}
              >
                <div className="single-service2 text-center">
                  <div className="single-custom-sale img">
                    <img src={publicUrl + item.icon} alt="icons" />
                  </div>
                  <div className="details readeal-top">
                    <h3>{item.number}</h3>
                    <h5>{item.title}</h5>
                    {/* <Link className="readmore-btn" to="/grant-details">{ item.btntxt } <i className="la la-arrow-right" /></Link> */}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      
    );
  }
}

export default serviceGrants;
