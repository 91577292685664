import React, { Component, useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { useDispatch } from "react-redux";
// import { getPosts } from "./actions/posts";
import { createStore, applyMiddleware, compose } from "redux";
// import Posts from './components/Posts/Posts';
import {
  BrowserRouter as Router,
  HashRouter,
  Route,
  Switch,
} from "react-router-dom";
import blogdata from "./data/blogdata.json";
// import Singleblogdata from "./data/single-blogdata.json";
import HomeV1 from "./components/home-v1";
// import HomeV2 from "./components/home-v2";
// import HomeV3 from "./components/home-v3";
// import HomeV4 from "./components/home-v4";
// import Property from "./components/property";
// import AvilableProperty from "./components/availavbe-property";
// import PropertiesByCity from "./components/properties-by-city";
// import RecentProperties from "./components/recent-properties";
import PropertyDetails from "./components/property-details";
import About from "./components/about";
// import Advisor from "./components/advisor";
import Pricing from "./components/pricing";
// import UserList from "./components/user-list";
// import Registraion from "./components/registration";
import Error from "./components/error";
import Faq from "./components/faq";
import News from "./components/news";
import NewsDetails from "./components/news-details";
import Contact from "./components/contact";
import SearchMap from "./components/search-map";
import SearchGrid from "./components/search-grid";
import SearchList from "./components/search-list";
import AddNew from "./components/enquire";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import reducers from "./reducers";
import Posts from "./components/Posts/Posts";
import Form from "./components/Form/Form";
import Post from "./components/Posts/Post/Post";
import Consultation from "./components/Consultation";
// import { Context as SearchContext } from './context/SearchContext';
import CombineProviders from "./Contexts/CombineProviders";

const store = createStore(reducers, compose(applyMiddleware(thunk)));

// const App = () => {
// class Root extends Component {
const Root = () => {
  const dispatch = useDispatch();
  // const [currentId, setCurrentId] = useState(null);

  // useEffect(() => {
  //   dispatch(getPosts());
  // }, [dispatch]);

  //   render() {
  return (
    <Router>
      <HashRouter basename="/">
        <div>
          <Switch>
            <Route exact path="/" component={HomeV1} />
            {/* <Route path="/home-v2" component={HomeV2} />
              <Route path="/home-v3" component={HomeV3} />
              <Route path="/home-v4" component={HomeV4} /> */}
            {/* <Route path="/property" component={Property} /> */}
            {/* <Route path="/availavbe-property" component={AvilableProperty} /> */}
            {/* <Route path="/properties-by-city" component={PropertiesByCity} /> */}
            {/* <Route path="/recent-properties" component={RecentProperties} /> */}
            <Route path="/grant-details/:id" component={PropertyDetails} />
            <Route path="/about" component={About} />
            {/* <Route path="/advisor" component={Advisor} /> */}
            <Route path="/pricing" component={Pricing} />
            {/* <Route path="/user-list" component={UserList} /> */}
            {/* <Route path="/registration" component={Registraion} /> */}
            <Route path="/consultation" component={Consultation} />
            <Route path="/error" component={Error} />
            {/* <Route path="/faq" component={Faq} /> */}
            {/* <Route path="/news" component={News} /> */}
            {/* <Route path="/news-details" component={NewsDetails} /> */}
            <Route path="/contact" component={Contact} />
            {/* <Route path="/search-map" component={SearchMap} /> */}
            <Route path="/search-grid" component={SearchGrid} />
            <Route path="/search-list" component={SearchList} />
            <Route path="/enquire" component={AddNew} />
            <Route path="/posts" component={Posts} />
            <Route path="/form" component={Form} />
            <Route component={Error} />
          </Switch>
        </div>
      </HashRouter>
    </Router>
  );
};
// }

export default Root;

ReactDOM.render(
  <CombineProviders>
    <Provider store={store}>
      <Root />
    </Provider>
  </CombineProviders>,
  document.getElementById("realdeal")
);
