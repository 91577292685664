import React, { Component, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import footerdata from "../../data/footerdata.json";
import { useForm, ValidationError } from "@formspree/react";
import SearchList from "../section-components/search-list";

const Footer_v1 = () => {

  const history = useHistory();
  
  useEffect(() => {
    let publicUrl = process.env.PUBLIC_URL + "/";
    const minscript = document.createElement("script");
    minscript.async = true;
    minscript.src = publicUrl + "assets/js/main.js";

    document.body.appendChild(minscript);
  }, []);

  const [state, handleSubmit] = useForm("xwkavezl");

  let publicUrl = process.env.PUBLIC_URL + "/";
  let imgattr = "Footer logo";
  const inlineStyle = {
    backgroundImage: "url(" + publicUrl + footerdata.footerbg + ")",
  };

  const goToQuicklink = (link) => {
    history.push(`/${publicUrl}/${link}`);
}

  return (
    <footer className="footer-area">
      <div className="container">
        <div className="footer-top">
          <div className="row">
            <div className="col-sm-4">
              <a className="footer-logo" href="#">
                <img src={publicUrl + footerdata.footerlogo} alt={imgattr} />
              </a>
            </div>
            {/* <div className="col-sm-8">
                      <div className="footer-social text-sm-right">
                      FOLLOW US
                        <ul className="social-icon">
                         { footerdata.socialicon.map( ( item, i )=>
                            <li key={i}>
                              <a href={ item.url } target="_blank"><i className={ item.icon } /></a>
                            </li>
                          ) }
                        </ul>
                      </div>  
                    </div> */}
          </div>
        </div>
        <div className="footer-bottom">
          <div className="row">
            <div className="col-lg-3 col-sm-6">
              <div className="widget widget_nav_menu">
                <h4 className="widget-title">
                  {footerdata.popularserces.title}
                </h4>
                <ul>
                  {footerdata.popularserces.links.map((item, i) => (
                    <li className="readeal-top" key={i}>
                      <Link to={`/search-list/${item.url}`}>{item.title}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="widget widget_nav_menu">
                <h4 className="widget-title" >{footerdata.Homepress.title}</h4>
                <ul>
                  {footerdata.Homepress.links.map((item, i) => (
                    <li className="readeal-top" key={i}>
                      <Link to={`/search-list/${item.url}`}>{item.title}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="widget widget_nav_menu">
                <h4 className="widget-title">{footerdata.quicklink.title}</h4>
                <ul>
                  {footerdata.quicklink.links.map((item, i) => (
                    <li className="readeal-top" key={i}>
                      <Link to={item.url}>{item.title}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            {state.succeeded ? (
              <p>Thanks for subscribing!</p>
            ) : (
              <div className="col-lg-3 col-sm-6">
                <form
                  className="widget widget-subscribe"
                  onSubmit={handleSubmit}
                >
                  <div className="rld-single-input">
                    {/* <label htmlFor="name">Full Name</label> */}
                    <input
                      required={"true"}
                      type="text"
                      placeholder="Full Name"
                      id="name"
                      type="name"
                      name="name"
                    />
                  </div>
                  <div className="rld-single-input">
                    {/* <label htmlFor="email">Email Address</label> */}
                    <input
                      required={"true"}
                      id="email2"
                      type="email"
                      name="email"
                      placeholder="your@email.com"
                    />
                    <ValidationError
                      className="rld-single-input"
                      style={{ margin: "10px" }}
                      prefix="Email"
                      field="email"
                      errors={state.errors}
                    />
                  </div>
                  <button
                    type="submit"
                    disabled={state.submitting}
                    className="btn btn-yellow w-100"
                  >
                    Subscribe
                  </button>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer_v1;
