import React, { Component } from "react";
import sectiondata from "../../data/sections.json";
import parse from "html-react-parser";
import { Link } from "react-router-dom";

class Ads extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imagealt = "image";
    let data = sectiondata.ads;

    const inlineStyle = {
      backgroundImage:
        "url(" + publicUrl + "assets/img/banner/back7orange.png)",
      opacity: 0.9

    };

    return (
      <div className="call-to-action-area pd-top-90 pd-bottom-60">
        <div className="container-for-grantCTA">
          <div className="call-to-action" style={inlineStyle}>
            <div className="cta-content">
              <h3 className="title">
              Need Help?
              </h3>
              <h2 className="title">Hire a Grant Writing Expert.</h2>
              <Link className="btn btn-white" to={data.url}>
                {data.btntxt}
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Ads;
